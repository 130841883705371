(function() {

    'use strict';

		console.log('initAccordionScript !');
		// 
    // App.Accordion = function(container, options) {
    //     this.opts = $.extend(this.defaults, options);
    //     this.container = container;
		//
    //     this.initAccordion();
    // };
		//
    // App.Accordion.prototype.initAccordion = function() {
		//
    //     $('.item').each(function() {
		//
    //         var el = $(this),
    //             header = el.find('.header'),
    //             content = el.find('.content');
		//
    //         header.on('click', function(event) {
		//
    //             if(el.hasClass('open')) {
    //                 el.removeClass('open');
    //                 content.slideUp(200);
    //             } else {
    //                 el.addClass('open');
    //                 content.slideDown(200);
    //             }
		//
    //             event.preventDefault();
    //         });
    //     });
    // };

}());
